/**
 * Template for an individual tag page, showing items with that tag.
 */

import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import PostPreview from "../components/preview";

const TagPage = ({ pageContext, data }) => {
    const { tag } = pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? "" : "s"
    } tagged with "${tag}"`;

    return (
        <Layout location={'/tags/' + tag} title={tag}>
            <Sidebar/>

            <div className="content main-content">
                <h1>{tagHeader}</h1>
                <ol className="content post-list">
                    {edges.map(({ node }) => {
                        const { slug } = node.frontmatter;
                        return (
                            <li key={slug}>
                                <PostPreview post={node}/>
                            </li>
                        )
                    })}
                </ol>
                <Link to="/tags">All tags</Link>
            </div>
        </Layout>
    )
};

TagPage.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            slug: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
};

export default TagPage

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            slug
            description
          }
        }
      }
    }
  }
`;
